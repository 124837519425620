import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { ProjectSummary } from "../../components/projects/ProjectSummary";

const BrooksideSubdivision = () => (
  <Layout>
    <SEO
      title="Epic Way Subdivision"
      image={"/epicway.jpg"}
      description="The scope of works involved civil works and new service
      installations for a five-lot residential subdivision."
    />

    <div className="row g-0">
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">
            Epic Way Subdivision
          </h1>
          <ProjectSummary
            name="Epic Way Subdivision"
            location="Newlands, Wellington"
            client="Private Residential Client"
            engineer="Spencer Holmes"
          />
          <p>
            The scope of works involved civil works and new service
            installations for a five-lot residential subdivision.
          </p>
          <p>
            The extent of works included; stripping, excavation and filling
            required for the driveway and services trenches, removal of the
            vegetation, the stripping and stockpiling of some topsoil, stripping
            and removal from site of unsuitable soil and tree roots, the
            excavation, placement and compaction of hardfill, installation of
            new water, sewer, stormwater and power services to the proposed
            5-lots, fill in accordance pavement preparation and installation of
            hard surfacing, inclusive of kerb and channelling and other
            foundations.
          </p>
        </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        <StaticImage
          // aspectRatio={16 / 11}
          transformOptions={{ cropFocus: "center" }}
          objectPosition="50% 50%"
          src="../../images/epicway.jpg"
          alt="driveway"
        />
      </div>
    </div>
  </Layout>
);

export default BrooksideSubdivision;
