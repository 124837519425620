import React from "react";

export const ProjectSummary = (props) => {
  const { name, type, location, client, engineer } = props;

  return (
    <ul className="project-summary list-unstyled mb-4">
      {name && (
        <li>
          <img src="/cone-icon.svg" alt="cone icon" />
          <div>
            <h6 className="mb-0">Project Name</h6>
            <span>{name}</span>
          </div>
        </li>
      )}
      {type && (
        <li>
          <img src="/digger-icon.svg" alt="digger icon" />
          <div>
            <h6 className="mb-0">Type of works provided</h6>
            <span>{type}</span>
          </div>
        </li>
      )}
      {location && (
        <li>
          <img src="/pin-icon.svg" alt="pin icon" />
          <div>
            <h6 className="mb-0">Project location</h6>
            <span>{location}</span>
          </div>
        </li>
      )}
      {client && (
        <li>
          <img src="/person-icon.svg" alt="person icon" />
          <div>
            <h6 className="mb-0">Client</h6>
            <span>{client}</span>
          </div>
        </li>
      )}
      {engineer && (
        <li>
          <img src="/person-icon-2.svg" alt="person icon" />
          <div>
            <h6 className="mb-0">Engineer</h6>
            <span>{engineer}</span>
          </div>
        </li>
      )}
    </ul>
  );
};
